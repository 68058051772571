<template>
  <div class="deal-with-facilities">
    <x-table
      :no-data-text="CA('farmInfo_check') ? '暂无数据' : '暂无数据查询权限'"
      :columns="table.columns"
      :data="table.data"
      :loading="table.loading"
      :config="config"
      @add="add"
      @search="search"
      @page-change="pageChange"
      @loadExpend="loadExpend"
      @loadEnter="loadEnter"
      :showSummary="true"
      :summaryMethod="handleSummary"
    ></x-table>

    <Modal
      v-model="modal.show"
      :title="modal.title"
      @on-visible-change="modalShow"
      :width="900"
    >
      <Form ref="form" :model="form" :rules="rules" :label-width="120">
        <Row>
          <!-- <Col span="12">
            <FormItem label="标题">
              <Input
                clearable
                v-model="form.title"
                style="width: 260px"
                placeholder="请输入标题"
              ></Input>
            </FormItem>
          </Col> -->
          <Col span="12">
            <FormItem label="养殖基地" prop="farmName">
              <Input
                readonly
                style="width: 260px"
                placeholder="请选择养殖基地"
                @on-focus="chooseBase"
                v-model="form.farmName"
              ></Input>
            </FormItem>
          </Col>
          <Col span="12">
            <FormItem label="街报数据" prop="num">
              <Input v-model="form.num" style="width: 260px"></Input>
            </FormItem>
          </Col>
          <Col span="12">
            <FormItem label="备案年份">
              <DatePicker
                v-model="form.year"
                clearable
                type="year"
                placeholder="请选择备案年份"
                style="width: 260px"
              ></DatePicker>
            </FormItem>
          </Col>
          <Col span="12">
            <FormItem label="备案月份">
              <DatePicker
                v-model="form.month"
                clearable
                type="month"
                placeholder="请选择备案月份"
                style="width: 260px"
              ></DatePicker>
            </FormItem>
          </Col>
          <Col span="12">
            <FormItem label="存栏">
              <Input
                style="width: 260px"
                placeholder="请输入存栏"
                v-model="form.amount"
              ></Input>
            </FormItem>
          </Col>
          <Col span="12">
            <FormItem label="年出栏">
              <Input
                style="width: 260px"
                placeholder="请输入年出栏"
                v-model="form.such"
              ></Input>
            </FormItem>
          </Col>
          <!-- <Col span="12">
            <FormItem label="规模类型">
              <Input
                style="width: 260px"
                placeholder="请输入规模类型"
                v-model="form.sacleInfo"
              ></Input>
            </FormItem>
          </Col> -->
          <!-- <Col span="12">
            <FormItem label="畜禽代码">
              <Input
                style="width: 260px"
                placeholder="请输入畜禽代码"
                v-model="form.livestockCode"
              ></Input>
            </FormItem>
          </Col> -->
          <Col span="12">
            <FormItem label="上报时间">
              <DatePicker
                v-model="form.recordDate"
                clearable
                type="date"
                placeholder="请选择上报时间"
                style="width: 260px"
              ></DatePicker>
            </FormItem>
          </Col>
          <Col span="12">
            <FormItem label="畜禽种类">
              <Select
                v-model="form.specie"
                placeholder="请选择畜禽种类"
                style="width: 260px"
              >
                <Option
                  v-for="(item, index) in catData"
                  :key="index"
                  :value="item.id + '/' + item.name"
                  >{{ item.name }}</Option
                >
              </Select>
            </FormItem>
          </Col>
          <Col span="12">
            <FormItem label="地址编码">
              <Cascader
                :data="areaList"
                v-model="form.regionCode"
                :load-data="loadData"
                change-on-select
                placeholder="请选择区域"
                style="width: 260px"
              ></Cascader>
            </FormItem>
          </Col>
          <Col span="12">
            <FormItem label="填报备注">
              <Input
                style="width: 260px"
                placeholder="请输入填报备注"
                v-model="form.reportRemark"
                type="textarea"
              ></Input>
            </FormItem>
          </Col>
          <Col span="12">
            <FormItem label="备注">
              <Input
                style="width: 260px"
                placeholder="请输入备注"
                v-model="form.remark"
                type="textarea"
              ></Input>
            </FormItem>
          </Col>
        </Row>
      </Form>
      <p slot="footer" style="text-align: center">
        <Button type="primary" :loading="modal.submitLoading" @click="submit"
          >提交</Button
        >
        <Button @click="() => (modal.show = false)">取消</Button>
      </p>
    </Modal>
    <choose-base
      v-model="chooseBaseShow"
      :farmId="farmId"
      @on-change="getBaseInfo"
    ></choose-base>

    <Modal
      v-model="exportModal.show"
      title="导出"
      @on-visible-change="exportModalShow"
    >
      <Form
        ref="exportForm"
        :model="exportForm"
        :rules="exportRules"
        :label-width="100"
      >
        <FormItem label="年" prop="year">
          <DatePicker
            v-model="exportForm.year"
            clearable
            type="year"
            style="width: 260px"
          ></DatePicker>
        </FormItem>
        <FormItem label="月" prop="month">
          <Select v-model="exportForm.month" multiple style="width: 260px">
            <Option
              v-for="(item, index) in months"
              :value="item"
              :key="index"
              >{{ item }}</Option
            >
          </Select>
        </FormItem>
      </Form>
      <p slot="footer" style="text-align: center">
        <Button
          type="primary"
          :loading="exportModal.submitLoading"
          @click="exportSubmit"
          >提交</Button
        >
        <Button @click="() => (exportModal.show = false)">取消</Button>
      </p>
    </Modal>
  </div>
</template>

<script>
import CU from "@/common/util";
import chooseBase from "./chooseBase";
import moment from "moment";
import axios from "axios";
import { baseUrl } from "@/common/http/api";
export default {
  name: "",
  components: {
    chooseBase,
  },
  data() {
    return {
      table: {
        columns: [
          // {
          //   title: "标题",
          //   minWidth: 100,
          //   key: "title",
          // },
          {
            title: "养殖基地",
            minWidth: 250,
            key: "farmName",
          },
          {
            title: "街报数据",
            minWidth: 100,
            key: "num",
          },
          {
            title: "备案年份",
            width: 100,
            key: "year",
          },
          {
            title: "备案月份",
            width: 100,
            key: "month",
          },
          {
            title: "存栏",
            minWidth: 100,
            key: "amount",
          },
          {
            title: "年出栏",
            minWidth: 100,
            key: "such",
          },
          {
            title: "上报时间",
            minWidth: 180,
            key: "recordDate",
          },
          {
            title: "畜禽种类",
            minWidth: 100,
            key: "speciesName",
          },
          {
            title: "地址编码",
            minWidth: 100,
            key: "regionCodeName",
          },
          {
            title: "养殖场地址",
            minWidth: 250,
            key: "address",
          },
          {
            title: "操作",
            width: 150,
            render: (h, { row }) => {
              return (
                <div>
                  {this.CA("farmInfo_edit") && (
                    <a
                      style="margin-right: 10px"
                      onClick={() => this.edit(row)}
                    >
                      编辑
                    </a>
                  )}
                  {this.CA("farmInfo_del") && (
                    <Poptip
                      confirm
                      transfer
                      title="确定删除吗?"
                      on-on-ok={() => this.del(row.id)}
                    >
                      <a>删除</a>
                    </Poptip>
                  )}
                </div>
              );
            },
          },
        ],
        data: [],
        loading: false,
      },
      page: {
        pageNo: 1,
        pageSize: 10,
        total: 0,
      },
      search_data: {},
      modal: {
        show: false,
        title: "",
        submitLoading: false,
      },
      form: {
        id: "",
        title: "",
        year: "",
        month: "",
        farmId: "",
        farmName: "",
        amount: "",
        such: "",
        recordDate: "",
        reportRemark: "",
        scaleInfo: "",
        livestockCode: "",
        specie: "",
        species: "",
        speciesName: "",
        regionCode: [],
        remark: "",
        num: "",
      },
      rules: {
        farmName: [{ required: true, message: "请选择养殖基地" }],
        num: [
          { required: true, message: "请填写街报数据" },
          {
            validator(rule, value, callback) {
              if (!CU.validateNumber(value, 0, undefined, 0)) {
                callback(rule.message);
                return;
              }
              callback();
            },
            message: "街报数据只能是整数",
          },
        ],
      },
      //选择基地弹框
      chooseBaseShow: false,
      farmId: "",
      areaList: [],
      catData: [],
      months: [
        "01",
        "02",
        "03",
        "04",
        "05",
        "06",
        "07",
        "08",
        "09",
        "10",
        "11",
        "12",
      ],
      exportForm: {
        year: "",
        month: [],
        // pageSize: "",
      },
      exportModal: {
        show: false,
        submitLoading: false,
      },
      exportRules: {
        year: [{ required: true, message: "请选择年份" }],
        month: [{ required: true, type: "array", message: "请选择月份" }],
      },
      sumAmount: [],
      sumSuch: [],
    };
  },
  computed: {
    config() {
      let config = {
        filter: {
          add: {
            addBtnName: "新增上报",
            ca: "farmInfo_add",
          },
          width: 220,
          loadModel: {
            url: "https://castoff.hogdata.cn/app/备案上报.xlsx",
          },
          loadExpend: {
            loadExpendName: "导出",
            // ca: "farmers_export",
          },
          loadEnter: {
            loadName: "导入",
            // ca: "farmInfo_import",
          },
          filterBox: [
            {
              conditionName: "基地名称",
              component: "input",
              field: "farmName",
              defaultValue: "",
            },
            {
              conditionName: "畜禽种类",
              component: "select",
              field: "speciesName",
              defaultValue: "",
              data: this.catData,
              parameterField: "name",
              showField: "name",
              filterable: true,
            },
            {
              conditionName: "区域",
              component: "cascader",
              field: "regionCode",
              defaultValue: [],
              data: this.areaList,
              isLoadData: true,
              loadData: this.loadData,
            },
            {
              conditionName: "标题",
              component: "input",
              field: "title",
              defaultValue: "",
            },
            {
              conditionName: "年份",
              component: "date",
              type: "year",
              field: "year",
              defaultValue: "",
            },
            {
              conditionName: "月份",
              component: "date",
              type: "month",
              field: "month",
              defaultValue: "",
            },
          ],
        },
        // summary: [
        //   {
        //     sumName: "存栏",
        //     sumVal: this.sumHousingArea,
        //     unit: "m²",
        //   },
        //   {
        //     sumName: "年出栏",
        //     sumVal: this.sumDesignNum,
        //     unit: "m²",
        //   },
        // ],
        page: { ...this.page },
      };
      return config;
    },
  },
  methods: {
    //导出
    loadExpend() {
      this.exportModal.show = true;
    },
    loadEnter() {
      let input = document.createElement("input");
      input.type = "file";
      input.onchange = () => {
        try {
          let file = input.files[0];
          let formdata = new FormData();
          formdata.append("file", file);
          formdata.append("userId", localStorage.getItem("userId"));
          formdata.append("companyNo", localStorage.getItem("companyNo"));
          axios({
            method: "post",
            url: baseUrl.URL + "excel/readrecordreport",
            data: formdata,
            headers: {
              Authorization: `Bearer_${localStorage.token}`,
              "Content-Type": "multipart/form-data",
            },
          }).then((res) => {
            if (res.data.code == 200) {
              this.$Message.success("导入成功");
              this.getList();
            } else {
              this.$Message.warning(res.data.message);
            }
          });
        } catch (e) {
          console.log(e);
        }
      };
      input.click();
    },
    add() {
      this.farmId = "";
      this.modal = {
        show: true,
        title: "新增上报",
        submitLoading: false,
      };
    },

    search(value) {
      this.page.pageNo = 1;
      let n;
      for (let i = 0; i < value.regionCode.length; i++) {
        n = value.regionCode[value.regionCode.length - 1];
      }
      let month;
      let year;
      if (value.month) {
        month = moment(value.month).format("MM");
      }
      if (value.year) {
        year = moment(value.year).format("YYYY");
      }
      let obj = {
        ...value,
        regionCode: n,
        month: month,
        year: year,
      };
      this.search_data = obj;
      this.getList();
    },

    pageChange(pageNo) {
      this.page.pageNo = pageNo;
      this.getList();
    },
    getList() {
      if (!this.CA("farmInfo_check")) return;
      this.table.loading = true;
      this.$post(this.$api.REPORT_RECORD.LIST, {
        pageNo: this.page.pageNo,
        pageSize: this.page.pageSize,
        ...this.search_data,
      })
        .then((res) => {
          this.table.data = res.list;
          this.page.total = +res.total;
        })
        .finally(() => {
          this.table.loading = false;
        });
    },
    getCategory() {
      this.$post(this.$api.PRODUCT_CATEGORY.LIST, {
        type: "2",
      }).then((res) => {
        this.catData = res.list;
      });
    },
    submit() {
      this.$refs.form.validate().then((res) => {
        if (!res) return;
        let params = { ...this.form };
        this.form.year && (params.year = moment(this.form.year).format("YYYY"));
        this.form.month &&
          (params.month = moment(this.form.month).format("MM"));
        this.form.regionCode.length > 0 &&
          (params.regionCode = this.form.regionCode.join(","));
        this.form.specie && (params.species = this.form.specie.split("/")[0]);
        this.form.specie &&
          (params.speciesName = this.form.specie.split("/")[1]);
        delete params.specie;
        let url;
        if (params.id) {
          url = this.$api.REPORT_RECORD.UPDATE;
        } else {
          url = this.$api.REPORT_RECORD.ADD;
        }
        this.$post(url, params).then(() => {
          this.$Message.success(params.id ? "修改成功！" : "增加成功！");
          this.getList();
          this.modal.show = false;
        });
      });
    },
    edit(row) {
      for (let key in this.form) {
        this.form[key] = row[key];
      }
      this.form.month = row.year + "-" + row.month;
      this.form.regionCode = row.regionCode.split(",");
      this.form.specie = row.species + "/" + row.speciesName;
      this.farmId = row.farmId;
      this.modal = {
        show: true,
        title: "编辑上报",
        submitLoading: false,
      };
    },
    //省市区列表接口
    getAreaList() {
      this.$post(this.$api.AREA_MANAGE.LIST, {
        parentId: "500153",
      }).then((res) => {
        res.forEach((item) => {
          item.label = item.name;
          item.value = item.id;
          item.loading = false;
          item.children = [];
        });
        this.areaList = res;
      });
    },
    loadData(item, callback) {
      item.loading = true;
      this.$post(this.$api.AREA_MANAGE.LIST, {
        parentId: item.id,
      })
        .then((res) => {
          res.forEach((item) => {
            item.label = item.name;
            item.value = item.id;
            if (item.hasChild == "true") {
              item.loading = false;
              item.children = [];
            }
          });
          item.children = res;
          callback();
        })
        .finally(() => {
          item.loading = false;
        });
    },
    chooseBase() {
      this.chooseBaseShow = true;
    },
    getBaseInfo(val) {
      if (val) {
        this.form.farmId = val.split("/")[0];
        this.form.farmName = val.split("/")[1];
      }
      this.chooseBaseShow = false;
    },
    del(id) {
      this.$post(this.$api.REPORT_RECORD.DELETE, {
        id,
      }).then(() => {
        this.$Message.success("删除成功");
        this.getList();
      });
    },
    modalShow(visible) {
      if (visible) return;
      this.form = {
        id: "",
        title: "",
        year: "",
        month: "",
        farmId: "",
        farmName: "",
        amount: "",
        such: "",
        recordDate: "",
        reportRemark: "",
        scaleInfo: "",
        livestockCode: "",
        species: "",
        speciesName: "",
        regionCode: [],
        remark: "",
        num: "",
      };
      this.$refs.form.resetFields();
    },

    exportModalShow(visible) {
      if (visible) return;
      this.exportForm = {
        year: "",
        month: [],
        // pageSize: "",
      };
      this.$refs.exportForm.resetFields();
    },
    handleSummary({ columns, data }) {
      const sums = {};
      columns.forEach((column, index) => {
        const key = column.key;
        if (index === 0) {
          sums[key] = {
            key,
            value: "统计",
          };
          return;
        }
        const values = data.map((item) => Number(item[key]));
        if (column.key == "amount" || column.key == "such") {
          if (!values.every((value) => isNaN(value))) {
            const v = values.reduce((prev, curr) => {
              const value = Number(curr);
              if (!isNaN(value)) {
                return prev + curr;
              } else {
                return prev;
              }
            }, 0);
            sums[key] = {
              key,
              value: v,
            };
          } else {
            sums[key] = {
              key,
              value: "",
            };
          }
        } else {
          sums[key] = {
            key,
            value: "",
          };
        }
      });
      return sums;
    },
    exportSubmit() {
      this.$refs.exportForm.validate().then((res) => {
        if (!res) return;
        let params = { ...this.exportForm };
        params.year = moment(this.exportForm.year).format("YYYY");
        params.month = this.exportForm.month.join(",");
        // params.pageSize = this.page.pageSize;
        // params.pageNo = this.page.pageNo
        this.exportModal.submitLoading = true;
        axios({
          method: "post",
          url: baseUrl.URL + this.$api.FAMRMING.RECORD_EXPORT,
          data: {
            ...params,
            userId: localStorage.userId,
            companyNo: localStorage.companyNo,
          },
          responseType: "blob",
          headers: {
            Authorization: `Bearer_${localStorage.token}`,
          },
        })
          .then((res) => {
            let elink = document.createElement("a");
            elink.download = `备案信息.xlsx`;
            elink.style.display = "none";
            let blob = new Blob([res.data], {
              type: "application/vnd.ms-excel",
            });

            elink.href = URL.createObjectURL(blob);
            document.body.appendChild(elink);
            elink.click();
            document.body.removeChild(elink);
            URL.revokeObjectURL(elink.href);
            this.exportModal.show = false;
          })
          .finally(() => {
            this.exportModal.submitLoading = false;
          });
      });
    },
  },
  mounted() {
    this.getList();
    this.getAreaList();
    this.getCategory();
  },
};
</script>

<style lang="less" scoped>
.deal-with-facilities {
  width: 100%;
  height: 100%;
}
</style>